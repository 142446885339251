
// @ts-nocheck


export const localeCodes =  [
  "nl",
  "de",
  "nl-be"
]

export const localeLoaders = {
  "nl": [{ key: "../../../node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl.json", load: () => import("../../../node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl.json" /* webpackChunkName: "locale__builds_tra_webshop_frontend_node_modules__64xsarus_npm_nuxt3_base_src_nuxt3_base_i18n_nl_json" */), cache: true },
{ key: "../i18n/nl.json", load: () => import("../i18n/nl.json" /* webpackChunkName: "locale__builds_tra_webshop_frontend_src_tra_i18n_nl_json" */), cache: true },
{ key: "../../../node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl.json", load: () => import("../../../node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl.json" /* webpackChunkName: "locale__builds_tra_webshop_frontend_node_modules__64xsarus_npm_nuxt3_base_src_nuxt3_base_i18n_nl_json" */), cache: true },
{ key: "../i18n/nl.json", load: () => import("../i18n/nl.json" /* webpackChunkName: "locale__builds_tra_webshop_frontend_src_tra_i18n_nl_json" */), cache: true }],
  "de": [{ key: "../../../node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/de.json", load: () => import("../../../node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/de.json" /* webpackChunkName: "locale__builds_tra_webshop_frontend_node_modules__64xsarus_npm_nuxt3_base_src_nuxt3_base_i18n_de_json" */), cache: true },
{ key: "../i18n/de.json", load: () => import("../i18n/de.json" /* webpackChunkName: "locale__builds_tra_webshop_frontend_src_tra_i18n_de_json" */), cache: true },
{ key: "../../../node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/de.json", load: () => import("../../../node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/de.json" /* webpackChunkName: "locale__builds_tra_webshop_frontend_node_modules__64xsarus_npm_nuxt3_base_src_nuxt3_base_i18n_de_json" */), cache: true },
{ key: "../i18n/de.json", load: () => import("../i18n/de.json" /* webpackChunkName: "locale__builds_tra_webshop_frontend_src_tra_i18n_de_json" */), cache: true }],
  "nl-be": [{ key: "../../../node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl.json", load: () => import("../../../node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl.json" /* webpackChunkName: "locale__builds_tra_webshop_frontend_node_modules__64xsarus_npm_nuxt3_base_src_nuxt3_base_i18n_nl_json" */), cache: true },
{ key: "../../../node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl-be.json", load: () => import("../../../node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl-be.json" /* webpackChunkName: "locale__builds_tra_webshop_frontend_node_modules__64xsarus_npm_nuxt3_base_src_nuxt3_base_i18n_nl_be_json" */), cache: true },
{ key: "../i18n/nl.json", load: () => import("../i18n/nl.json" /* webpackChunkName: "locale__builds_tra_webshop_frontend_src_tra_i18n_nl_json" */), cache: true },
{ key: "../i18n/nl-be.json", load: () => import("../i18n/nl-be.json" /* webpackChunkName: "locale__builds_tra_webshop_frontend_src_tra_i18n_nl_be_json" */), cache: true },
{ key: "../../../node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl.json", load: () => import("../../../node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl.json" /* webpackChunkName: "locale__builds_tra_webshop_frontend_node_modules__64xsarus_npm_nuxt3_base_src_nuxt3_base_i18n_nl_json" */), cache: true },
{ key: "../../../node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl-be.json", load: () => import("../../../node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl-be.json" /* webpackChunkName: "locale__builds_tra_webshop_frontend_node_modules__64xsarus_npm_nuxt3_base_src_nuxt3_base_i18n_nl_be_json" */), cache: true },
{ key: "../i18n/nl.json", load: () => import("../i18n/nl.json" /* webpackChunkName: "locale__builds_tra_webshop_frontend_src_tra_i18n_nl_json" */), cache: true },
{ key: "../i18n/nl-be.json", load: () => import("../i18n/nl-be.json" /* webpackChunkName: "locale__builds_tra_webshop_frontend_src_tra_i18n_nl_be_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute"
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "nl",
      "language": "nl-NL",
      "storeCode": "tra_nl_nl",
      "domain": "https://www.travelbags.nl",
      "name": "NL",
      "flag": "🇳🇱",
      "numberFormats": {
        "currency": {
          "style": "currency",
          "currency": "EUR"
        }
      },
      "datetimeFormats": {
        "default": {
          "year": "numeric",
          "month": "2-digit",
          "day": "2-digit"
        },
        "short": {
          "year": "numeric",
          "month": "short",
          "day": "2-digit"
        },
        "long": {
          "year": "numeric",
          "month": "long",
          "day": "2-digit",
          "weekday": "long"
        }
      },
      "files": [
        "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl.json",
        "/builds/tra/webshop/frontend/src/tra/i18n/nl.json",
        "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl.json",
        "/builds/tra/webshop/frontend/src/tra/i18n/nl.json"
      ]
    },
    {
      "code": "de",
      "language": "de-DE",
      "storeCode": "tra_de_de",
      "domain": "https://www.travelbags.de",
      "name": "DE",
      "flag": "🇩🇪",
      "numberFormats": {
        "currency": {
          "style": "currency",
          "currency": "EUR"
        }
      },
      "datetimeFormats": {
        "default": {
          "year": "numeric",
          "month": "2-digit",
          "day": "2-digit"
        },
        "short": {
          "year": "numeric",
          "month": "short",
          "day": "2-digit"
        },
        "long": {
          "year": "numeric",
          "month": "long",
          "day": "2-digit",
          "weekday": "long"
        }
      },
      "files": [
        "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/de.json",
        "/builds/tra/webshop/frontend/src/tra/i18n/de.json",
        "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/de.json",
        "/builds/tra/webshop/frontend/src/tra/i18n/de.json"
      ]
    },
    {
      "code": "nl-be",
      "language": "nl-BE",
      "storeCode": "tra_be_nl",
      "domain": "https://www.travelbags.be",
      "files": [
        "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl.json",
        "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl-be.json",
        "/builds/tra/webshop/frontend/src/tra/i18n/nl.json",
        "/builds/tra/webshop/frontend/src/tra/i18n/nl-be.json",
        "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl.json",
        "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl-be.json",
        "/builds/tra/webshop/frontend/src/tra/i18n/nl.json",
        "/builds/tra/webshop/frontend/src/tra/i18n/nl-be.json"
      ],
      "name": "BE",
      "flag": "🇧🇪",
      "numberFormats": {
        "currency": {
          "style": "currency",
          "currency": "EUR"
        }
      },
      "datetimeFormats": {
        "default": {
          "year": "numeric",
          "month": "2-digit",
          "day": "2-digit"
        },
        "short": {
          "year": "numeric",
          "month": "short",
          "day": "2-digit"
        },
        "long": {
          "year": "numeric",
          "month": "long",
          "day": "2-digit",
          "weekday": "long"
        }
      }
    }
  ],
  "defaultLocale": "nl-be",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "i18n",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://www.travelbags.be",
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "nl",
    "language": "nl-NL",
    "storeCode": "tra_nl_nl",
    "domain": "https://www.travelbags.nl",
    "name": "NL",
    "flag": "🇳🇱",
    "numberFormats": {
      "currency": {
        "style": "currency",
        "currency": "EUR"
      }
    },
    "datetimeFormats": {
      "default": {
        "year": "numeric",
        "month": "2-digit",
        "day": "2-digit"
      },
      "short": {
        "year": "numeric",
        "month": "short",
        "day": "2-digit"
      },
      "long": {
        "year": "numeric",
        "month": "long",
        "day": "2-digit",
        "weekday": "long"
      }
    },
    "files": [
      {
        "path": "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl.json"
      },
      {
        "path": "/builds/tra/webshop/frontend/src/tra/i18n/nl.json"
      },
      {
        "path": "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl.json"
      },
      {
        "path": "/builds/tra/webshop/frontend/src/tra/i18n/nl.json"
      }
    ]
  },
  {
    "code": "de",
    "language": "de-DE",
    "storeCode": "tra_de_de",
    "domain": "https://www.travelbags.de",
    "name": "DE",
    "flag": "🇩🇪",
    "numberFormats": {
      "currency": {
        "style": "currency",
        "currency": "EUR"
      }
    },
    "datetimeFormats": {
      "default": {
        "year": "numeric",
        "month": "2-digit",
        "day": "2-digit"
      },
      "short": {
        "year": "numeric",
        "month": "short",
        "day": "2-digit"
      },
      "long": {
        "year": "numeric",
        "month": "long",
        "day": "2-digit",
        "weekday": "long"
      }
    },
    "files": [
      {
        "path": "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/de.json"
      },
      {
        "path": "/builds/tra/webshop/frontend/src/tra/i18n/de.json"
      },
      {
        "path": "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/de.json"
      },
      {
        "path": "/builds/tra/webshop/frontend/src/tra/i18n/de.json"
      }
    ]
  },
  {
    "code": "nl-be",
    "language": "nl-BE",
    "storeCode": "tra_be_nl",
    "domain": "https://www.travelbags.be",
    "files": [
      {
        "path": "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl.json"
      },
      {
        "path": "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl-be.json"
      },
      {
        "path": "/builds/tra/webshop/frontend/src/tra/i18n/nl.json"
      },
      {
        "path": "/builds/tra/webshop/frontend/src/tra/i18n/nl-be.json"
      },
      {
        "path": "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl.json"
      },
      {
        "path": "/builds/tra/webshop/frontend/node_modules/@xsarus-npm/nuxt3-base/src/nuxt3-base/i18n/nl-be.json"
      },
      {
        "path": "/builds/tra/webshop/frontend/src/tra/i18n/nl.json"
      },
      {
        "path": "/builds/tra/webshop/frontend/src/tra/i18n/nl-be.json"
      }
    ],
    "name": "BE",
    "flag": "🇧🇪",
    "numberFormats": {
      "currency": {
        "style": "currency",
        "currency": "EUR"
      }
    },
    "datetimeFormats": {
      "default": {
        "year": "numeric",
        "month": "2-digit",
        "day": "2-digit"
      },
      "short": {
        "year": "numeric",
        "month": "short",
        "day": "2-digit"
      },
      "long": {
        "year": "numeric",
        "month": "long",
        "day": "2-digit",
        "weekday": "long"
      }
    }
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
